//src/components/RequestTokenForm.js
import React, { useState } from 'react';
import api from '../services/api';
import Spinner from './Spinner';
import ResetPasswordForm from './ResetPasswordForm';
import Message from './Message';

function RequestTokenForm({ onTokenRequested }) {
  const [username, setUserName] = useState('');
  const [CompanyDB, setCompanyDB] = useState(''); 
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageStatus, setMessageStatus] = useState(''); 
  const [showResetPassword, setShowResetPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');
    setMessageStatus('');
    try {
      const response = await api.post('/getUser', { username, CompanyDB });
      setMessage(response.data.message);
      setMessageStatus(response.status);
      setTimeout(() => {
        setShowResetPassword(true);
        setMessage(''); 
        setMessageStatus('');
      }, 5000); // Duração da mensagem: 2000ms ou 2 segundos
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.error
                          ? error.response.data.error
                          : 'Erro ao solicitar token. Tente novamente.';
      setMessage(errorMessage);
      setMessageStatus(error.response ? error.response.status : 500);
      setTimeout(() => {
        setMessage(''); 
        setMessageStatus('');
      }, 5000);
    } finally {
      setIsLoading(false);
    }
  };

  if (showResetPassword) {
    return <ResetPasswordForm />;
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <input type="text" value={username} onChange={(e) => setUserName(e.target.value)} placeholder="Nome de usuário" />
            <select
              className="select-custom"
              value={CompanyDB}
              onChange={(e) => setCompanyDB(e.target.value)}
            >
              <option value="">Selecione uma Base de Dados</option>
              <option value="SBO_SOLUBIO_PRD_NEW">01-[PRODUCAO]</option>
              <option value="SBO_SOLUBIO_TST2">02-[HOMOLOCACAO]</option>
              <option value="SBO_SOLUBIO_TST10">10-[DESENVOLVIMENTO]</option>
            </select>
            <button type="submit">Solicitar Token</button>
          </>
        )}
      </form>
      {!isLoading && <Message status={messageStatus} text={message} />}
    </div>
  );
}

export default RequestTokenForm;
